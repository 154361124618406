<template>
  <div class="course-manage">
    <div class="header-select-date">
      <div class="all-date" :class="{ active: allActive }" @click="allOnClick">
        <van-icon name="notes-o" size="16" />
        <div class="text">全部</div>
      </div>
      <div class="current-week">
        <div
          v-for="(item, index) in showDateList"
          :key="'hxrq' + index"
          :class="['week-day-item', { active: item.active }, { huidian: item.isBeforeHave }, { huangdian: item.isAfterHave }]"
          @click="weekDayOnClick(item, index)"
        >
          <div class="week-text">{{ item.week }}</div>
          <div class="day-text">{{ item.dayText }}</div>
        </div>
      </div>
      <div class="more-date" @click="calendarShow = true">
        <van-icon name="notes-o" />
        <div class="text">{{ moreDateText }}</div>
      </div>
    </div>
    <div class="cou-man-list">
      <van-list
        v-model="loading"
        :finished="finished"
        finished-text="没有更多了"
        :error.sync="error"
        error-text="请求失败，点击重新加载"
        :immediate-check="false"
        @load="getList"
      >
        <cou-man-card v-for="(item, index) in courseList" :key="'kcglkp' + index" :detail="item" />
      </van-list>
    </div>

    <van-calendar
      v-model="calendarShow"
      :show-confirm="false"
      color="#367BF4"
      :min-date="minDate"
      :max-date="maxDate"
      :formatter="calendarFormatter"
      safe-area-inset-bottom
      @confirm="calendarOnConfirm"
    />
  </div>
</template>

<script>
import couManCard from '../components/couManCard.vue';
import { getCourseManageListUrl, getCourseManageHaveCouDatListUrl } from '../api.js';
import { toRegister } from '@/utils/common.js';
import moment from 'moment';
import { mapState } from 'vuex';
import Vue from 'vue';
import { Icon, Calendar } from 'vant';

moment.locale('zh-cn');
Vue.use(Icon);
Vue.use(Calendar);

export default {
  name: 'nightSchoolCourseManage',
  components: {
    couManCard
  },
  data() {
    return {
      courseList: [],
      allActive: true,
      selectedDate: '',
      calendarShow: false,
      haveCourseDate: [],
      total: 0,
      queryForm: {
        curPage: 1,
        pageSize: 10
      },
      loading: false,
      finished: false,
      error: false,
      minDate: new Date(moment('2024-01-01')),
      maxDate: new Date(moment('2034-12-31'))
    };
  },
  computed: {
    ...mapState(['userId']),
    moreDateText() {
      if (this.selectedDate) {
        // return moment(this.selectedDate).format('M.D');
        return '更多日期';
      } else {
        return '更多日期';
      }
    },
    showDateList() {
      const result = [];
      const currentDate = this.selectedDate || moment().format('YYYY-MM-DD');
      new Array(7).fill(0).forEach((item, index) => {
        const date = moment(currentDate)
          .add(index - 3, 'day')
          .format('YYYY-MM-DD 星期dd');
        const day = date.slice(0, 10);
        const week = date.slice(-4);
        const flag = this.haveCourseDate.includes(date.slice(0, 10));
        result.push({
          day,
          dayText: moment(day).format('M.D'),
          week,
          active: this.selectedDate && this.selectedDate === day,
          isBeforeHave: flag && new Date(day + ' 00:00:00') < new Date(moment().format('YYYY-MM-DD 00:00:00')),
          isAfterHave: flag && new Date(day + ' 00:00:00') >= new Date(moment().format('YYYY-MM-DD 00:00:00'))
        });
      });
      return result;
    }
  },
  async created() {
    if (await toRegister(this.$route.path, this.$route.query, '热门活动报名')) {
      this.getDateList();
      this.getList();
    }
  },
  methods: {
    getList() {
      this.loading = true;
      this.finished = false;
      this.$axios
        .get(getCourseManageListUrl, { params: { selectDay: this.selectedDate, userId: this.userId, ...this.queryForm } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = res.data || {};
            res.data.records = Array.isArray(res.data.records) ? res.data.records : [];
            res.data.records.forEach(item => {
              item.coursePictureUrlList = item.coursePictureUrl.split('|').filter(item => item);
              item.fitApplyPersonList = item.fitApplyPerson.split(',').filter(item => item);
            });
            this.courseList.push(...res.data.records);
            this.queryForm.curPage++;
            this.total = res.data.total;
            this.loading = false;
            if (this.courseList.length >= this.total) {
              this.finished = true;
            }
          } else {
            this.loading = false;
            this.error = true;
          }
        })
        .catch(() => {
          this.loading = false;
          this.error = true;
        });
    },
    getDateList() {
      this.$axios
        .get(getCourseManageHaveCouDatListUrl, { params: { userId: this.userId } })
        .then(res => {
          if (res && res.code === 200) {
            res.data = Array.isArray(res.data) ? res.data : [];
            this.haveCourseDate = res.data;
          }
        })
        .catch(() => {});
    },
    allOnClick() {
      if (this.allActive) return;
      this.allActive = true;
      this.selectedDate = '';
      this.courseList = [];
      this.queryForm.curPage = 1;
      this.getList();
      this.$nextTick(() => {
        document.querySelectorAll('.week-day-item')[3].scrollIntoView({ behavior: 'smooth', inline: 'center' });
      });
    },
    weekDayOnClick(value, index) {
      if (this.selectedDate === value.day) return;
      this.selectedDate = value.day;
      this.allActive = false;
      this.courseList = [];
      this.queryForm.curPage = 1;
      this.getList();
      this.$nextTick(() => {
        document.querySelector('.week-day-item.active').scrollIntoView({ behavior: 'smooth', inline: 'center' });
      });
    },
    calendarOnConfirm(value) {
      this.selectedDate = moment(value).format('YYYY-MM-DD');
      this.allActive = false;
      this.calendarShow = false;
      this.courseList = [];
      this.queryForm.curPage = 1;
      this.getList();
      this.$nextTick(() => {
        document.querySelector('.week-day-item.active').scrollIntoView({ behavior: 'smooth', inline: 'center' });
      });
    },
    calendarFormatter(day) {
      const today = moment().format('YYYY-MM-DD 00:00:00');
      const riliDay = moment(day.date).format('YYYY-MM-DD 00:00:00');
      if (this.haveCourseDate.includes(moment(day.date).format('YYYY-MM-DD'))) {
        if (new Date(today) > new Date(riliDay)) {
          day.className = 'huidian';
        } else {
          day.className = 'huangdian';
        }
      }
      return day;
    }
  }
};
</script>

<style lang="less" scoped>
.course-manage {
  box-sizing: border-box;
  height: calc(100%);
  height: calc(100% - constant(safe-area-inset-bottom));
  height: calc(100% - env(safe-area-inset-bottom));
  overflow-x: hidden;
  overflow-y: auto;
  padding-top: 144px;
  background-color: #fafafa;
}
.header-select-date {
  box-sizing: border-box;
  width: 100%;
  height: 144px;
  padding-top: 10px;
  position: fixed;
  left: 0px;
  top: 0px;
  z-index: 2;
  background-color: #ffffff;
  display: flex;
  .all-date {
    flex-shrink: 0;
    box-sizing: border-box;
    width: 132px;
    height: 104px;
    background-color: #ffffff;
    color: #000000;
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    font-size: 24px;
    line-height: 24px;
    box-shadow: 4px 0px 4px -4px rgba(0, 0, 0, 0.24);
    .text {
      box-sizing: border-box;
      text-align: center;
      margin-top: 10px;
    }
    &.active {
      background-color: #367bf4;
      color: #ffffff;
      box-shadow: none;
    }
  }
  .current-week {
    flex-grow: 1;
    box-sizing: border-box;
    height: 144px;
    display: flex;
    overflow-x: visible;
    overflow-y: auto;
    padding: 0px 10px;
    .week-day-item {
      box-sizing: border-box;
      flex-shrink: 0;
      width: 104px;
      height: 104px;
      border-radius: 8px;
      background-color: #f6f7f9;
      color: rgba(0, 0, 0, 0.85);
      margin: 0px 10px;
      display: flex;
      flex-direction: column;
      justify-content: center;
      align-items: center;
      position: relative;
      .week-text {
        box-sizing: border-box;
        font-size: 24px;
        line-height: 24px;
      }
      .day-text {
        box-sizing: border-box;
        font-size: 22px;
        line-height: 22px;
        margin-top: 10px;
      }
      &.active {
        border: 1px solid #367bf4;
        color: #367bf4;
        background: rgba(54, 123, 244, 0.1);
      }
      &.huidian::after {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #adadad;
        position: absolute;
        left: 50%;
        bottom: -18px;
        transform: translate(-50%, 0);
      }
      &.huangdian::after {
        content: '';
        width: 10px;
        height: 10px;
        border-radius: 50%;
        background-color: #f59b23;
        position: absolute;
        left: 50%;
        bottom: -18px;
        transform: translate(-50%, 0);
      }
    }
    &::-webkit-scrollbar {
      display: none;
    }
  }
  .more-date {
    flex-shrink: 0;
    width: 132px;
    height: 104px;
    background-color: #ffffff;
    box-shadow: -4px 0px 4px -2px rgba(0, 0, 0, 0.24);
    display: flex;
    flex-direction: column;
    justify-content: center;
    align-items: center;
    flex-wrap: wrap;
    .text {
      box-sizing: border-box;
      font-size: 24px;
      line-height: 24px;
      text-align: center;
      margin-top: 10px;
    }
  }
}
.cou-man-list {
  box-sizing: border-box;
  height: 100%;
  overflow-x: hidden;
  overflow-y: auto;
  padding: 20px 30px 0px 30px;
  .cou-man-card {
    margin-bottom: 20px;
  }
}

/deep/ .van-calendar__day.huidian {
  &::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #adadad;
    position: absolute;
    left: 50%;
    bottom: 18px;
    transform: translate(-50%, 0);
  }
}
/deep/ .van-calendar__day.huangdian {
  &::after {
    content: '';
    width: 10px;
    height: 10px;
    border-radius: 50%;
    background-color: #f59b23;
    position: absolute;
    left: 50%;
    bottom: 18px;
    transform: translate(-50%, 0);
  }
}
</style>
