<template>
  <div class="cou-man-card" @click="couManOnClick">
    <div class="cou-man-status" :class="courseStatusClassMap[detail.courseStatus]">{{ courseStatusMap[detail.courseStatus] }}</div>
    <div class="card-title">开课周期：{{ detail.courseOpenStart }} ~ {{ detail.courseOpenEnd }}</div>
    <div class="image-info-box">
      <div class="course-img">
        <img v-if="detail.coursePictureUrlList[0]" class="cou-img" :src="detail.coursePictureUrlList[0]" />
        <img v-else class="cou-img" src="../img/kctp_zw.png" />
      </div>
      <div class="infos-box">
        <div class="course-title">
          <div class="title-text">{{ detail.courseName }}</div>
          <van-tag color="#E02020" size="large" plain style="background-color: rgba(224, 32, 32, 0.1)">{{
            fitApplyPersonMap[detail.fitApplyPersonList[0]]
          }}</van-tag>
        </div>
        <div class="text-item" v-for="(item, index) in detail.courseSettingList" :key="'kksj' + index">
          {{ getDayWeekMonth(item) }}
        </div>
        <van-tag class="zlr-tag" color="#367BF4" size="large" plain style="background-color: rgba(54, 123, 244, 0.1)">{{ detail.teacher }}</van-tag>
        <div class="text-item">
          已报名/开班人数：{{ detail.applyUserCount }}人 /
          {{ detail.isAstrictPersons === 1 ? `${detail.minPerson}~${detail.maxPerson}人` : '不限人数' }}
        </div>
        <div class="limit-people-number-bar">
          <div v-if="detail.isAstrictPersons === 1" class="lower-bar" :style="{ width: (detail.applyUserCount / detail.maxPerson) * 100 + '%' }"></div>
          <div v-if="detail.isAstrictPersons === 1" class="value-line" :style="{ left: (detail.minPerson / detail.maxPerson) * 100 + '%' }"></div>
        </div>
      </div>
    </div>
  </div>
</template>

<script>
import { courseStatusMap, courseStatusClassMap, fitApplyPersonMap, dayWeekMonthMap, getDayWeekMonth } from '../map.js';
import Vue from 'vue';
import { Tag } from 'vant';

Vue.use(Tag);

export default {
  name: 'couManCard',
  components: {},
  props: {
    detail: {
      type: Object,
      default: () => ({
        coursePictureUrlList: [],
        fitApplyPersonList: [],
        courseSettingList: []
      })
    }
  },
  data() {
    return {
      courseStatusMap,
      courseStatusClassMap,
      fitApplyPersonMap,
      dayWeekMonthMap
    };
  },
  created() {},
  methods: {
    couManOnClick() {
      this.$router.push({
        name: 'nightSchoolCourseSituation',
        query: { id: this.detail.id }
      });
    },
    getDayWeekMonth
  }
};
</script>

<style lang="less" scoped>
.cou-man-card {
  background: #ffffff;
  box-shadow: 0px 4px 32px 0px rgba(66, 114, 153, 0.1);
  border-radius: 16px;
  padding: 16px 18px;
  position: relative;
  .cou-man-status {
    box-sizing: border-box;
    width: 160px;
    height: 44px;
    position: absolute;
    top: 0px;
    right: 0px;
    color: #ffffff;
    display: flex;
    justify-content: center;
    align-items: center;
    border-radius: 0px 16px 0px 44px;
    font-size: 24px;
    box-shadow: 0px 4px 8px 0px rgba(225, 225, 225, 0.5);
    padding-left: 10px;
    &.yjs {
      background: linear-gradient(180deg, #ededed 0%, #d8d8d8 100%);
      color: #a8a8a8;
    }
    &.kczqz {
      background: linear-gradient(180deg, #ffc055 0%, #fe9615 100%);
    }
    &.bmz {
      background: linear-gradient(180deg, #73a8ff 0%, #2e7bff 100%);
    }
  }
  .card-title {
    box-sizing: border-box;
    font-size: 24px;
    line-height: 34px;
    color: #367bf4;
  }
  .image-info-box {
    box-sizing: border-box;
    margin-top: 10px;
    display: flex;
    .course-img {
      flex-shrink: 0;
      width: 250px;
      height: 246px;
      border-radius: 16px;
      overflow: hidden;
      box-sizing: border-box;
      margin-right: 20px;
      .cou-img {
        box-sizing: border-box;
        width: 100%;
        height: 100%;
        border-radius: 16px;
      }
    }
    .infos-box {
      box-sizing: border-box;
      flex-grow: 1;
      width: calc(100% - 270px);
      .course-title {
        box-sizing: border-box;
        display: flex;
        align-items: center;
        .title-text {
          box-sizing: border-box;
          white-space: nowrap;
          overflow: hidden;
          text-overflow: ellipsis;
          font-size: 32px;
          line-height: 46px;
          font-weight: 700;
          color: rgba(0, 0, 0, 0.85);
          padding-right: 10px;
        }
        .van-tag {
          flex-shrink: 0;
        }
        margin-bottom: 10px;
      }
      .text-item {
        font-size: 24px;
        line-height: 34px;
        color: rgba(0, 0, 0, 0.5);
      }
      .zlr-tag {
        margin: 10px 0px;
      }
      .limit-people-number-bar {
        margin-top: 4px;
        width: 100%;
        height: 16px;
        border-radius: 8px;
        background-color: #e6e6e6;
        position: relative;
        .lower-bar {
          position: absolute;
          left: 0px;
          top: 0px;
          border-radius: 8px;
          background-color: #367bf4;
          height: 100%;
          max-width: 100%;
        }
        .value-line {
          width: 2px;
          height: 100%;
          position: absolute;
          top: 0px;
          background-color: #fcaa3e;
        }
      }
    }
  }
}
</style>
