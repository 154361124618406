import { mapHelper } from '@/utils/utils.js';

// 批量导入图标
const iconContext = require.context('@/views/futureEdu/nightSchool/img', false, /tabbar_icon.{0,}\.png$/);
const iconMap = {};
iconContext.keys().forEach(key => {
  iconMap[key.slice(14, -4)] = iconContext(key);
});

// 夜校课程类型
const courseType = [
  { value: 1, text: '艺术' },
  { value: 2, text: '声乐' },
  { value: 3, text: '舞蹈' },
  { value: 4, text: '健康' },
  { value: 5, text: '文化' }
];
const { map: courseTypeMap, setOps: setCourseTypeOps } = mapHelper.setMap(courseType);

// 适用对象
const fitApplyPerson = [
  { value: 1, text: '不限对象' },
  { value: 2, text: '成年人' },
  { value: 3, text: '青少年' },
  { value: 4, text: '中老年人' },
  { value: 5, text: '婴幼儿' }
];
const { map: fitApplyPersonMap, setOps: setFitApplyPersonOps } = mapHelper.setMap(fitApplyPerson);

// 开课时间类型
const dayWeekMonth = [
  { text: '日', value: 1 },
  { text: '周', value: 2 },
  { text: '月', value: 3 }
];
const { map: dayWeekMonthMap, setOps: setDayWeekMonthOps } = mapHelper.setMap(dayWeekMonth);

// 周
const weeks = [
  { text: '一', value: '一' },
  { text: '二', value: '二' },
  { text: '三', value: '三' },
  { text: '四', value: '四' },
  { text: '五', value: '五' },
  { text: '六', value: '六' },
  { text: '日', value: '日' }
];
const { map: weeksMap, setOps: setWeeksOps } = mapHelper.setMap(weeks);

// 月
const months = [
  { text: '1', value: 1 },
  { text: '2', value: 2 },
  { text: '3', value: 3 },
  { text: '4', value: 4 },
  { text: '5', value: 5 },
  { text: '6', value: 6 },
  { text: '7', value: 7 },
  { text: '8', value: 8 },
  { text: '9', value: 9 },
  { text: '10', value: 10 },
  { text: '11', value: 11 },
  { text: '12', value: 12 },
  { text: '13', value: 13 },
  { text: '14', value: 14 },
  { text: '15', value: 15 },
  { text: '16', value: 16 },
  { text: '17', value: 17 },
  { text: '18', value: 18 },
  { text: '19', value: 19 },
  { text: '20', value: 20 },
  { text: '21', value: 21 },
  { text: '22', value: 22 },
  { text: '23', value: 23 },
  { text: '24', value: 24 },
  { text: '25', value: 25 },
  { text: '26', value: 26 },
  { text: '27', value: 27 },
  { text: '28', value: 28 },
  { text: '29', value: 29 },
  { text: '30', value: 30 },
  { text: '31', value: 31 }
];
const { map: monthsMap, setOps: setMonthsOps } = mapHelper.setMap(months);

// 签到（上课）状态
const signInStatus = [
  { text: '缺席', value: 0 },
  { text: '签到成功', value: 1 },
  { text: '请假', value: 2 },
  { text: '未开始', value: 4 },
  { text: '已取消', value: 5 }
];
const signInStatusColor = [
  { text: '#999999', value: 0 },
  { text: '#43C93E', value: 1 },
  { text: '#F59B23', value: 2 },
  { text: '#367BF4', value: 4 },
  { text: '#999999', value: 5 }
];
const { map: signInStatusMap, setOps: setSignInStatusOps } = mapHelper.setMap(signInStatus);
const { map: signInStatusColorMap } = mapHelper.setMap(signInStatusColor);

// 课程订单状态
const courseOrderStatus = [
  { text: '报名成功', value: 0 },
  { text: '待成班', value: 1 },
  { text: '已取消', value: 2 },
  { text: '成班失败', value: 3 }
  // { text: '待支付', value: 5 }
];
const courseOrderStatusClass = [
  { text: 'green', value: 0 },
  { text: 'blue', value: 1 },
  { text: 'gray', value: 2 },
  { text: 'gray', value: 3 }
  // { text: 'red', value: 5 }
];
const { map: courseOrderStatusMap, setOps: setCourseOrderStatusOps } = mapHelper.setMap(courseOrderStatus);
const { map: courseOrderStatusClassMap } = mapHelper.setMap(courseOrderStatusClass);

// 报名状态
const signUpStatus = [
  { text: '报名成功', value: 0 },
  { text: '待成班', value: 1 },
  { text: '已取消', value: 2 },
  { text: '成班失败', value: 3 }
];
const { map: signUpStatusMap, setOps: setSignUpStatusOps } = mapHelper.setMap(signUpStatus);

// 课程状态
const courseStatus = [
  // { value: 1, text: "未开始" },
  { value: 2, text: '课程周期中' },
  { value: 3, text: '报名中' },
  { value: 4, text: '已结束' },
  { value: 5, text: '已失效' }
];
const courseStatusClass = [
  // { value: 1, text: "" },
  { value: 2, text: 'kczqz' },
  { value: 3, text: 'bmz' },
  { value: 4, text: 'yjs' },
  { value: 5, text: 'yjs' }
];
const { map: courseStatusMap, setOps: setCourseStatusOps } = mapHelper.setMap(courseStatus);
const { map: courseStatusClassMap } = mapHelper.setMap(courseStatusClass);

// 签到状态
const clockInStatus = [
  { value: 0, text: '未签到' },
  { value: 1, text: '已签到' },
  { value: 2, text: '已请假' }
];
const clockInStatusClass = [
  { value: 0, text: 'wqd' },
  { value: 1, text: 'yqd' },
  { value: 2, text: 'yqj' }
];
const { map: clockInStatusMap, setOps: setClockInStatusOps } = mapHelper.setMap(clockInStatus);
const { map: clockInStatusClassMap } = mapHelper.setMap(clockInStatusClass);

const getDayWeekMonth = ({ dayWeekMonth: type, weeks, startTime, endTime }) => {
  let result = '每' + dayWeekMonthMap[type];
  if (type === 1) {
    result += '';
  } else if (type === 2) {
    result += weeksMap[weeks];
  } else if (type === 3) {
    result += monthsMap[weeks] + '号';
  } else {
    result += '';
  }
  result += ' ' + startTime.slice(0, 5) + '~' + endTime.slice(0, 5);
  return result;
};

export {
  iconMap,
  courseType,
  courseTypeMap,
  setCourseTypeOps,
  fitApplyPerson,
  fitApplyPersonMap,
  setFitApplyPersonOps,
  dayWeekMonth,
  dayWeekMonthMap,
  setDayWeekMonthOps,
  weeks,
  weeksMap,
  setWeeksOps,
  months,
  monthsMap,
  setMonthsOps,
  signInStatus,
  signInStatusMap,
  setSignInStatusOps,
  signInStatusColorMap,
  courseOrderStatus,
  courseOrderStatusMap,
  setCourseOrderStatusOps,
  courseOrderStatusClassMap,
  signUpStatus,
  signUpStatusMap,
  setSignUpStatusOps,
  courseStatus,
  courseStatusMap,
  setCourseStatusOps,
  courseStatusClassMap,
  clockInStatus,
  clockInStatusMap,
  setClockInStatusOps,
  clockInStatusClassMap,
  getDayWeekMonth
};
